<template>
    <div>
        <list-page
                :columns="columns"
                :headers="headers"
                title="Reseller Invoice List"
                icon="mdi-view-list"
                sort="invoiceNo"
                :descending="true"
                :show-add-button="false"
                stateendpoint="invoice.invoices"
                :actions="actions"
                :show-default-action-buttons="false"
                :action-buttons="actionButton"
                :show-invoice-button="true"
                :show-select="true"
                :mail-type="mailType"
        />
    </div>
</template>

<script>
    import ListPage from "../../components/ListPage";

    export default {
        name: "InvoiceByMail",
        metaInfo: {
            title: 'webexpressmedia.com',
            titleTemplate: 'Admin Dashboard - Reseller Invoice | %s',
            meta: [
                { name: 'robots', content: 'noindex' },
                { name: 'googlebot', content: 'noindex' }
            ]
        },
        components: {ListPage},
        data() {
            return {
                headers: [
                    {
                        text: 'Method',
                        value: 'paymentMethod'
                    },
                    {
                        text: 'Invoice No',
                        value: 'invoiceNo'
                    },
                    {
                        text: 'Email',
                        value: 'email'
                    },
                    {
                        text: 'Total',
                        value: 'totalAmount'
                    },
                    {
                        text: 'Status',
                        value: 'paymentStatus'
                    },
                    {
                        text: 'Is Prepaid?',
                        value: 'isPrepayment'
                    },
                    {
                        text: 'Created Date',
                        value: 'createdAt'
                    },
                    {
                        text: 'Updated Date',
                        value: 'updatedAt'
                    },
                    {
                        text: 'Paid Date',
                        value: 'paymentDate'
                    },
                    {
                        text: 'User',
                        value: 'user'
                    },
                    {
                        text: 'Removal Mail',
                        value: 'removalMail'
                    },
                    {
                        text: 'After Removal Mail',
                        value: 'afterRemovalMail'
                    },
                    {
                        text: 'Link',
                        value: 'refLink',
                        align: 'right',
                        sortable: false
                    },
                    {
                        text: '', value: 'actions', sortable: false
                    }
                ],
                columns: [
                    {value: 'refcode'},
                    {value: 'email'},
                    {value: 'price'},
                    {value: 'createdAt'},
                    {value: 'paymentStatus'},
                    {value: 'action'}
                ],
                actions: {
                    load: 'loadInvoices',
                    update: 'updateManualPayment',
                    restore: 'restoreInvoice',
                    remove: 'destroyInvoice',
                    destroy: 'destroyInvoicePermanent',
                    get: 'getTemplateByType',
                    paymentMailHandler: 'resendMailHandler',
                    reset: 'resetInvoiceHandler',
                    reminderMail: 'sendReminderMail',
                    afterReminderMail: 'sendAfterReminderMail',
                    sendMail: 'sendInvoiceEmail',
                    refund: 'refundInvoice'
                },
                actionButton: [
                    {
                        category: 'edit',
                        icon: 'mdi-pencil',
                        color: '',
                        text: 'Edit',
                        clickHandler: 'editInvoiceHandler'
                    },
                    {
                        category: 'delete',
                        icon: 'mdi-delete',
                        color: 'error',
                        text: 'Delete',
                        clickHandler: 'deleteItem'
                    },
                    {
                        category: 'deletePermanent',
                        icon: 'mdi-delete-alert',
                        color: 'error',
                        text: 'Destroy Permanent',
                        clickHandler: 'destroyItem'
                    },
                    {
                        category: 'restore',
                        icon: 'mdi-backup-restore',
                        color: '',
                        text: 'Restore',
                        clickHandler: 'restoreHandler'
                    },
                    {
                        category: 'reset',
                        icon: 'mdi-reload',
                        color: '',
                        text: 'Reset Invoice',
                        clickHandler: 'onResetInvoiceHandler'
                    },
                    {
                        category: 'link',
                        icon: 'mdi-eye',
                        color: '',
                        text: 'View Live Link',
                        clickHandler: 'linkHandler'
                    },
                    {
                        category: 'mailDetails',
                        icon: 'mdi-email-mark-as-unread',
                        color: '',
                        text: 'View Mail Details',
                        clickHandler: 'invoiceMailDetailsHandler'
                    },
                    {
                        category: 'resendMail',
                        icon: 'mdi-mail',
                        color: '',
                        text: 'Resend Payment Email',
                        clickHandler: 'resendPaymentMailHandler'
                    },
                    {
                        category: 'reminder',
                        icon: 'mdi-email-alert',
                        color: '',
                        text: 'Post Removal Payment Email',
                        clickHandler: 'reminderPaymentMailHandler'
                    },
                    {
                        category: 'afterReminder',
                        icon: 'mdi-delete-empty',
                        color: '',
                        text: 'After Post Removal Payment Email',
                        clickHandler: 'afterReminderPaymentMailHandler'
                    },
                    {
                        category: 'credit',
                        icon: 'mdi-dolly',
                        color: '',
                        text: 'Make a Manual Payment',
                        clickHandler: 'manualPaymentHandler'
                    },
                    {
                      category: 'refund',
                      icon: 'mdi-credit-card-refund',
                      color: '',
                      text: 'Refund Invoice',
                      clickHandler: 'refundInvoiceHandler'
                    }

                ],
                mailType: this.$route.query.mailType
            }
        },
        mounted() {
            this.$store.dispatch('loadAllAdminUsers');
            this.$store.dispatch('loadAllMethods', {status: false});
        }
    }
</script>

<style scoped>

</style>